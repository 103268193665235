import { AcpDeviceService } from 'core';
import { AcpAdaChatbotPlugin } from './ada-chatbot-plugin';
import acpAdaChatbotTemplate from './templates/ada-chatbot.html';

export class AcpAdaChatbotComponentController
  implements nsUtils.NsComponentController {
  private authed: string;
  isPermission: boolean;

  constructor(
    nsComponentDecorator,
    private acpDeviceService: AcpDeviceService,
    private acpAdaChatbotPlugin: AcpAdaChatbotPlugin
  ) {
    'ngInject';
    nsComponentDecorator(this, this);
  }

  get isAuthed() {
    return this.authed === 'true';
  }

  adaChatBotHandler() {
    this.acpAdaChatbotPlugin.toggle(this.isAuthed);
  }

  $onInit(): void {
    this.isPermission = this.acpAdaChatbotPlugin.permission;
  }

  isShowIcon() {
    return (
      this.isPermission &&
      window.adaEmbed &&
      (this.acpDeviceService.isWeb() ||
        (this.acpDeviceService.isCordova() && this.isAuthed))
    );
  }
}

export const acpAdaChatbotComponent: ng.IComponentOptions = {
  controller: AcpAdaChatbotComponentController,
  controllerAs: 'vm',
  bindings: {
    authed: '@'
  },
  template: acpAdaChatbotTemplate
};

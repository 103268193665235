import { AdaChatbotPlugin } from 'packages/ada-chatbot/plugin';

export class AcpAdaChatbotPlugin {
  permission: boolean;

  constructor(
    private acpConfigurationService: any,
    private webapiResource: nsWebclient.WebapiResourceService,
    private nsPermissions: nsUtils.NsPermissionsService
  ) {
    'ngInject';
  }

  async init() {
    const handle = this.acpConfigurationService.getAdaChatbotHandle();
    this.permission = await this.nsPermissions.requestPermission(
      'isAdaChatbotEnabled'
    );
    new AdaChatbotPlugin(this.permission, handle);
  }

  triggerWebAction() {
    const logAction = this.webapiResource<{ action: string }, void>({
      method: 'POST',
      path: `/v2/log/action`,
      format: 'json'
    });

    logAction({ action: 'ADA_CHATBOT_CLICKED' });
  }

  toggle(authed = false) {
    if (window.adaEmbed) {
      if (authed) {
        this.triggerWebAction();
      }

      AdaChatbotPlugin.toggle();
    }
  }
}
